.info-container {
    margin-top: -50px;
    margin-left: 30px;
}

.info-item {
    display: flex;
    margin-bottom: 10px;
}

.info-text {
    font-size: 15px;
    font-weight: 400;
}

.custom-icon {
    color: black;
    margin-top: 4px;
    margin-right: 10px;
    font-size: 15px;
}
