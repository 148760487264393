.about-me {
    margin: 15px;
    border: 3px solid rgba(128, 128, 128, 0.136);
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    background-color: rgb(246, 241, 241);
}
.intro {
    margin: 10px;
    font-size: 15px;
}
.intro h1 {
    text-transform: uppercase;
    font-size: 2rem;
}
.intro hr {
    height: 1px;
    background-color: gray;
    width: 100%;
    margin: 10px 0;
    border: none;
}